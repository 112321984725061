import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPhoneAlt} from "@fortawesome/free-solid-svg-icons"
import {faEnvelope} from "@fortawesome/free-regular-svg-icons"
import ContactForm from "../components/ContactForm"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact"/>
    <Container className="my-4">
      <h1 className="text-primary">Contact</h1>
      <p>
        It's good to talk! We would love to hear from new schools to plan together how we can help and support you. No matter how big or small your enquiry is, please phone, email or message us and let's talk.
      </p>
      <p className="mb-4">
        Stay connected with us too and gain access to helpful resources by following Waite Psychology on <a target="_blank" rel="noreferrer" href="https://www.facebook.com/waitepsychology/">Facebook</a> and <a target="_blank" rel="noreferrer" href="https://twitter.com/waitepsychology">Twitter</a>.
      </p>
      <Row xs={1} lg={3} className="justify-content-center">
        <Col className="text-center mb-4">
          <FontAwesomeIcon icon={faPhoneAlt} size="lg" className="text-primary mr-2"/><a href="tel:07702068694">07702 068 694</a>
        </Col>
        <Col className="text-center mb-4">
          <FontAwesomeIcon icon={faEnvelope} size="lg" className="text-primary mr-2"/><a href="mailto:jennifer@waitepsychology.co.uk">jennifer@waitepsychology.co.uk</a>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 8, offset: 2 }}>
          <ContactForm/>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ContactPage
